<template>
<div>
  <v-form ref="formMessage" >
    <p class="title-user-scr">Enviar mensaje</p>    
    <v-textarea  
      label="Mensaje"
      outlined
      v-model="message"  
      name="message"
    ></v-textarea>
    <v-btn depressed color="#00aff2" dark @click="handleSave(message)"> Enviar </v-btn>
  </v-form>
</div>
</template>

<script>

export default {
  data() {
    return {
     message: "",
    };
  },
  methods: {
    handleSave(message) {
      this.message = "";
      this.$emit('save', message);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>
