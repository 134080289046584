<template>    
      <div>       
        <v-row>
          <v-col cols="6" sm="6">
            <p class="title-user-scr"> Ticket {{ ticket.id }}</p>         
          </v-col>
          <v-col cols="6" sm="6">
            <v-btn color="primary" small fab dark  :to="{ name: 'mytickets' }" style="margin-inline-start:70%">
                  <v-icon>mdi-arrow-u-left-top</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        
        <v-card class="padding-card" color="white">       
            <v-form>
             
              <v-row>              
                <v-col cols="12" sm="12">
                  <span class="title-user">{{ ticket.subject }}</span>
                </v-col>
                <v-col cols="12" sm="12">
                  <span class="title-user-scr">Abierto</span><br>
                      {{ $dayjs(ticket.createdAt).format('lll') }}
                </v-col>
                <v-col v-if="ticket.dateClosed" cols="12" sm="12">                 
                  <span class="title-user-scr">Cerrado</span> <br>  
                  Por: {{ ticket.closedby.username }} <br>  
                  {{ $dayjs(ticket.dateClosed).format('lll') }}     
                </v-col>                   
                <v-col v-else cols="12" sm="12">
                  <v-btn depressed color="#00aff2" dark @click="close()"> Cerrar Ticket </v-btn>
                </v-col>
              </v-row>
            </v-form>
        </v-card>
  
        <p class="title-user-scr" style="padding-top: 50px"> Conversación </p>
        <v-card color="white" >
          <v-col v-for="(item, i) in shownCards" :key="i" cols="12">
            <div class="d-flex flex-no-wrap justify-space-between" :class="[item.userId == userId ? 'message-me' : 'message-you']" >
              <v-list-item>              
                <v-list-item-content style="margin-left: 15px">
                  <v-list-item-title >
                  </v-list-item-title>
                  <span class="title-text-info"> {{ item.message }} </span>
                  <span style="font-size: 12px"> {{ $dayjs(item.createdAt).format('lll') }} </span>            
                </v-list-item-content>
              </v-list-item>
            </div>       
          </v-col>
          <div class="text-center">
            <v-pagination v-model="page" :length="length"></v-pagination>
          </div>
        </v-card>
        
      
        <v-card style="margin-top:30px">
          <div style="padding:10px" >
            <TicketForm  @save="save"/>
          </div>
        </v-card>
      </div>
  
  </template>
  
  <script>
  
  import userTicketService from '@/services/users/tickets';
  import TicketForm from '@/components/TicketForm.vue';
  
  export default {  
    components: {
      TicketForm,
    }, 
    props: ['ticketId'],      
    created() {
        this.getMessages();
    },  
    methods: {    
      async getMessages() {     
          await userTicketService.fetchTicket( this.ticketId ).then((response) => {
          this.ticket = response.data;
          this.messages = response.data.messages
          console.log(this.messages);
        });
        console.log(this.messages);
      },
      save(message) {
        userTicketService.addTicketMessage( this.ticketId, message ).then((response) => {
          this.messages.push(response.data);
        });
      },
     
      close(){
          console.log('close');
          userTicketService.closeTicket( this.ticket.id ).then((response) => {
            console.log(response.data);
            this.ticket.dateClosed = response.data.dateClosed;
        }); 
      }
  
    },
    
    computed: {    
      userId() {
        console.log(this.$store.getters['session/userId']);
        return this.$store.getters['session/userId'];
      },
     
      length() {
       return Math.ceil(this.messages.length / 5);     
      },
      shownCards() {
        const { page, messages } = this;
        const number = 5; // Numero de elementos a mostrar por página
        return messages.slice((page - 1) * number, page * number);   
      },
      
    }, 
    data() {
      return {   
        page: 1,      
        ticket: {
          user:{
            lotPurchased:''
          }
        },
        messages: [],
      };
    },
    
   
  
  };
  </script>
  
  <style lang="scss" scoped>
  @import './src/assets/style.scss';
  </style>